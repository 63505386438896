.admin_header {
  background-color: #f8f9fa;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 800px; /* Match the Sidebar width */
  width: 100%;
  height: 60px;
  z-index: 1000;
}

.admin-header-left { margin: 0; font-size: 1.5em; top:10px; right: 20px; }
.admin-header-right { display: flex; align-items: center; right: 240px; }

.admin-profile default {margin-left: 10px; width: 30px; height: 30px; text-align: right; overflow: hidden; cursor: pointer;}