.admin-layout {
    display: flex;
}

.admin-main-content {
    margin-left: 240px;
    padding-top: 60px;
    width: calc(100% - 240px);
    background-color: #f9f9f9;
    min-height: 100vh;
}

.admin-area {
    padding: 20px;
}

.admin_code_list {
    display: flex;
    justify-content: column; 
    margin-top: 55px; 
    border-top: 3px solid #111;
    padding-top: 10px;
  }
  
.admin_code_list{
  display: flex;
  justify-content: flex-start; 
  align-items: center; 
  margin-bottom: 20px; 
  border-bottom: 1px dashed #111; 
  padding-bottom: 35px;
}
.search-bar {
  display: flex; 
  gap: 10px; 
  padding: 10px 0; 
  margin: 10px 0;
}

.code-management-container {
  display: flex;
  gap: 20px;
  height: 600px;
  margin-top: 20px;
}

.admin-code-mng {
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-y: auto;
  padding: 10px;
}
.admin-code-mng table {
  width: 100%;
  border-collapse: collapse;
}
.admin-code-mng th,
.admin-code-mng td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
.admin-code-mng tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.admin_code_details {
  flex: 1;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  height: 600px;
  overflow-y: auto;
}
.admin_code_details table {
  width: 100%;
  border-collapse: collapse;
}
.admin_code_details th,
.admin_code_details td,
.admin_code_details input {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
.admin_code_details tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.details-content {
  overflow-y: auto;
  flex: 1;
}

.code-detail-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.update-button {
  align-self: flex-end;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.admin_contents {
  margin: 0 auto;
  display: flex;
  gap: 20px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.admin_user_detail {
  flex: 1;
  margin-bottom: 35px;
}

.admin_auth_list {
  flex: 2;
  margin-top: 40px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.admin_detail_content {
  width: 100%;
}

.admin_detail_content ul {
  margin-top: 20px;
  padding-left: 0; /* 불필요한 패딩 제거 */
}

.admin_detail_content ul li {
  font-weight: 500;
  color: #333;
  display: flex;
  justify-content: flex-start;
  align-items: center; /* 가운데 정렬로 수정 */
  padding: 12px 0;
  border-bottom: 1px solid #ddd; /* 구분선 추가 */
}

.admin_detail_content ul li:last-child {
  border-bottom: none; /* 마지막 항목에는 구분선 제거 */
}

.admin_detail_content ul li p {
  font-size: 1.4rem;
  flex-grow: 0;
  width: 20%; /* 넓이를 20%로 변경하여 레이아웃 안정화 */
  margin: 0; /* 불필요한 여백 제거 */
}

.admin_detail_content ul li span {
  display: block;
  font-size: 1.6rem;
  flex-grow: 1;
  width: 80%;
  margin-left: 10px; /* 왼쪽에 여백 추가 */
}

.admin_auth_list table {
  width: 100%; /* 테이블이 전체 권한 목록 영역을 차지하도록 */
  border-collapse: collapse;
  margin-top: 20px;
}

.admin_auth_list th, .admin_auth_list td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.admin_profile_img {
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-top: 40px;
}

.admin_user_sub_title {
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: bold;
}