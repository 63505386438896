@charset "UTF-8";

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url(./font/NotoSansKR-Thin.woff2) format('woff2'),
         url(./font/NotoSansKR-Thin.woff) format('woff');/*,
         url(./font/NotoSansKR-Thin.otf) format('opentype');*/
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 350;
    src: url(./font/NotoSansKR-Light.woff2) format('woff2'),
         url(./font/NotoSansKR-Light.woff) format('woff');/*,
         url(./font/NotoSansKR-Light.otf) format('opentype');*/
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url(./font/NotoSansKR-Regular.woff2) format('woff2'),
         url(./font/NotoSansKR-Regular.woff) format('woff');/*,
         url(./font/NotoSansKR-Regular.otf) format('opentype');*/
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url(./font/NotoSansKR-Medium.woff2) format('woff2'),
         url(./font/NotoSansKR-Medium.woff) format('woff');/*,
         url(./font/NotoSansKR-Medium.otf) format('opentype');*/
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url(./font/NotoSansKR-Bold.woff2) format('woff2'),
         url(./font/NotoSansKR-Bold.woff) format('woff');/*,
         url(./font/NotoSansKR-Bold.otf) format('opentype');*/
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url(./font/NotoSansKR-Black.woff2) format('woff2'),
         url(./font/NotoSansKR-Black.woff) format('woff');/*,
         url(./font/NotoSansKR-Black.otf) format('opentype');*/
}



