@charset "UTF-8";

/******************** my page ********************/
.my_profile {display: flex; justify-content: flex-start; align-items: flex-start; border-bottom: 0.5px dashed #111; padding: 70px 0 70px;}
/* .my_profile > div {width: 33.33%;} */
.my_profile .title {font-size: 3.8rem; font-weight: 100; letter-spacing: -0.03em; margin-bottom: 10px;}
.my_profile .photo {width: 260px; height: 260px; background-color: #E9E9E9; overflow: hidden; flex-grow: 0; margin-right: 130px;}

.my_profile .my_info {flex-direction: column; border: none; padding: 0; flex-grow: 1;}
.my_profile .my_info .data {max-width: 100%; width: 100%; font-size: 1.8rem; font-weight: 400; letter-spacing: -0.05em; line-height: 1; margin-bottom: 20px;}
.my_profile .my_info .data > span {font-size: 3.0rem; letter-spacing: -0.03em; margin-right: 10px;}

.my_profile .modify {margin-bottom: 22px;}
.my_profile .modify a {font-size: 1.8rem; font-weight: 400; letter-spacing: -0.05em; line-height: 1.2; border-bottom: 1px solid #111;}
.my_profile .modify p {font-size: 1.2rem; font-weight: 350; letter-spacing: -0.03em; margin-top: 5px;}

.my_profile .my_info .choice {width: 100%;}
.my_profile .my_info .choice .tx_tit02 {margin-bottom: 5px;}
.my_profile .my_info .choice .tab {flex-direction: column; align-items: flex-start;}
.my_profile .my_info .choice .tab_title::after {display: none;}
.my_profile .my_info .contest {padding: 5px 0;}

/* .my_schedule {margin-top: 0;} */

.contents.mypage.apply .my_profile, .contents.mypage.wish .my_profile, .contents.mypage.point .my_profile {display: none;}
.contents.mypage .list_type1 {border-top: none; margin-top: 70px;}
.contents.mypage .list_type1 .search_box {margin-left: 10px;}
.contents.mypage .list_type1 .bar .tx_type03 {flex-grow: 0;}
.contents.mypage .list_type1 .list_date a {font-size: 1.4rem; margin: 0 3px;}
.contents.mypage .list_type1 ul.list {padding: 0;}
.contents.mypage .list_type1 ul li {padding: 15px 0; margin-bottom: 0; font-size: 1.6rem;}
.contents.mypage .list_type1 ul li:first-of-type {padding: 25px 0 15px;}
.contents.mypage .list_type1 ul li:last-of-type {padding: 15px 0 25px;}
.contents.mypage .list_type1 ul.list .end {color: #555;}
.contents.mypage .list_type1 ul.list li b {padding-right: 0;}
.contents.mypage .list_type1 ul.list li > span {padding-left: 0;}

.mypage.point .list_type1 .bar .my_point {flex-grow: 0; font-size: 2.0rem; font-weight: 700; letter-spacing: -0.03em; color: #333;}
.mypage.point .list_type1 .bar .date_search {flex-grow: 2; display: flex; align-items: center; justify-content: flex-end;}
.mypage.point .list_type1 .bar {padding-bottom: 15px; border-bottom: none;}
.mypage .date_search .btn_date {font-size: 1.2rem; font-weight: 500; background: #777; color: #fff; padding: 6px 5px;  border-radius: 5px; line-height: 1.2; min-height: 28px; min-width: 63px;}
.mypage .date_search > div {margin-right: 3px; display: flex; align-items: center;}
.mypage .date_search > div label {display: inline-block; background: url(../images/common/ico_calendar.svg) no-repeat 0 0; width: 27px; height: 24px; margin-left: 3px;}
/* .mypage .date_search > div::after {content: ""; display: block; clear: both; width: 80px; height: 28px; border: 1px solid #aaa; position: absolute; left: 0; top: 0;} */
.mypage .date_search > span {margin: 0 3px 0 0; font-size: 2.0rem; font-weight: 500;}
.mypage .date_search input[type="date"] {border-radius: 0; width: 80px; height: 28px;}
.mypage .date_search input[type="date"]::-webkit-calendar-picker-indicator,
.mypage .date_search input[type="date"]::-webkit-inner-spin-button {display: none; appearance: none;}
/* .mypage .date_search input[type="date"]::-webkit-calendar-picker-indicator {background: url(../images/common/ico_calendar.svg) no-repeat 0 0; display: block; width: 27px; height: 24px; border-width: thin; opacity: 1; color: rgba(0, 0, 0, 0); cursor: pointer; position: absolute; right: -2px; top: 0;} */
.mypage .date_search input[type="date"]::before {content: attr(data-placeholder); width: 100%;}
.mypage .date_search input[type="date"]:focus::before,
.mypage .date_search input[type="date"]:valid::before {display: none;}

/******************** Contest & Ground ********************/
.contents .top_line {display: flex; justify-content: space-between; align-items: flex-end;}
.contents .btn_return {font-size: 14px; font-weight: 350; color: #555; letter-spacing: -0.05em; position: relative; height: 17px; padding-right: 29px;}
.contents .btn_return span {position: absolute; right: 0; width: 19px; height: 3px; background-color: #777;}
.contents .btn_return span:nth-of-type(1) {top : 0;}
.contents .btn_return span:nth-of-type(2) {top : 7px;}
.contents .btn_return span:nth-of-type(3) {bottom : 0;}

.contents.contest, .contents.ground, .contents.event, .contents.cs_notice {margin-bottom: 35px;}
.contents .slide_list.dot_line {margin-top: 20px; overflow: visible;}
.contents .slide_list.dot_line .dots {top: -43px; right: 0;}
.contents .slide_list.dot_line .slide_box {overflow: hidden;}
.contest .table_type1 tbody tr:first-of-type td {padding-top: 24px; padding-bottom: 12px;}
.contest .table_type1.default tbody tr:last-of-type td {padding-top: 12px; padding-bottom: 24px;}

.contest .summary, .contest .category, .contest .s_notice, .contest .prize, .contest .application, .ground .ground_section {border-top: 3px solid #111; margin-top: 20px; display: flex; justify-content: flex-start; align-items: flex-start; padding-top: 45px;}
.contest .sub_menu, .ground .sub_menu {margin-right: 50px;}
.contest .sub_menu .tab {display: flex; align-items: center; justify-content: flex-start; flex-direction: row;}
.contest .sub_menu .tab a {display: inline-block; border: 1px solid#aaa; border-radius: 5px; padding: 10px 8px; font-size: 1.4rem; font-weight: 500; letter-spacing: -0.03em; text-align: center; margin-right: 2px; height: 40px; min-width: 72px; max-width: 100%; flex-grow: 0;}
.contest .sub_menu .tab .active {background: #111; border: 1px solid#111; color: #fff;}

.contest .poster {width: 320px; height: 450px; background: #E9E9E9; margin-top: 35px;}
.contest .poster img{width: 100%; height: 100%;object-fit: fill;}
.contest .sub_menu .tab a:last-of-type {margin-right: 0; flex-grow: 4;}
.contest .btn_line {margin-top: 15px;}
.btn_type1.apply {max-height: 38px; height: auto; max-width: 278px; text-align: left; padding: 10px 10px; background: url(../images/01_contest/ico_apply.svg) no-repeat 98% center #111; width: 100%; font-size: 2.0rem; font-weight: 400; line-height: 1; letter-spacing: -0.05em;}
.btn_heart {background: url(../images/01_contest/ico_heart.svg) no-repeat center center; background-color: #111; opacity: .5; text-indent: -9999px; width: 38px; height: 38px; border-radius: 5px;}
.btn_heart_red {background: url(../images/01_contest/ico_heart_red.svg) no-repeat center center; background-color: #111; opacity: .5; text-indent: -9999px; width: 38px; height: 38px; border-radius: 5px;}
.contest .sub_menu > p {text-align: center; margin-top: 7px;}
.contest .tx_type04 {font-size: 1.4rem; font-weight: 400; letter-spacing: -0.01em; line-height: 1.45;}

.contest .title {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; padding: 6px 0;}
.contest .title h2 {font-size: 2.0rem; font-weight: 500; letter-spacing: -0.03em; line-height: 1.4;}
.contest .title h2 span {font-weight: 900;}
.summary_content .info, .category_content .info, .prize_content .info, .application_content .info {margin-top: 35px; text-align: justify; word-break: break-all; font-size: 1.4rem; letter-spacing: -0.03em; line-height: 1.57; padding-bottom: 45px;} 
.summary_content .info h3 {font-size: 1.6rem;}
.summary_content .info h3 + p {margin-bottom: 15px;}
.summary_content .info > div {margin: 20px 0;}
.summary_content .file {margin: 0 0 50px;}
.summary_content .file p, .notice_content .file p {font-weight: 500; margin-top: 15px; font-size: 1.4rem; letter-spacing: -0.03em;}

.category_content .info, .application_content .info {text-align: initial; padding-bottom: 0;}
.category_content .info .search_box, .application_content .info .search_box {margin-bottom: 15px;}
.category_content .info .search_box select, .application_content .info .search_box select {border-radius: 5px;}
.category_content .info table tbody tr td:nth-of-type(5n+2) {font-weight: 500;}

.notice_content .info {margin-top: 35px; word-break: break-all; font-size: 1.4rem; letter-spacing: -0.03em; line-height: 1.57;}
.notice_content .search_box input {background: #fff; border: 1px solid #aaa; padding: 5px; font-size: 1.2rem;  line-height: 1.2; height: 28px; width: 155px;}
.notice_content .search_box button {font-size: 1.2rem; font-weight: 500; background: #888; color: #fff; padding: 6px 5px; border-radius: 5px; line-height: 1.2; height: 28px; min-width: 78px;}
.notice_content .search_box a {display: inline-block; border-bottom: 1px solid #333; color: #333; font-size: 1.5rem; font-weight: 500; letter-spacing: -0.03em; margin-left: 20px;}

.notice_content .qna {border-top: 1px dashed #111; margin-top: 30px; text-align: justify;}
.notice_content .qna .question, .notice_content .qna .answer {padding: 30px; font-size: 1.4rem; font-weight: 350; letter-spacing: -0.03em; line-height: 1.715;}
.notice_content .qna .tit {font-weight: 700;}
.notice_content .qna .date {font-weight: 700;}
.notice_content .qna .answer {background: #f1f1f1;}
.notice_content .table_type1 {margin-top: 10px;}
.notice_content .table_type1 td {font-size: 1.2rem;}
.notice_content .table_type1 thead th:first-of-type, .notice_content .table_type1 tbody td:first-of-type {text-align: left;}
.notice_content .table_type1 tbody td:first-of-type {font-weight: 500; font-size: 1.4rem;}
.notice_content .table_type1 .date {color: #aaa;}
.notice_content .table_type1 .accordion {margin-top: 20px;}
.notice_content .table_type1 .accordion p {margin-bottom: 20px; text-align: justify;}
.notice_content .file {margin: 30px 0;}

/* 댓글 */
.notice_content .comment {margin: 30px 0 0;}
.notice_content .comment_input {display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;}
.notice_content .comment_input input {border: 1px solid #555; background: #fff; font-size: 1.2rem; min-height: 36px; padding: 5px; line-height: 1.2; width: calc(100% - 80px); max-width: 630px; order: 1;}
.notice_content .comment_input input::placeholder {color: #888;}
.notice_content .comment_input span {order: 2;}
.notice_content .comment_input button {height: 28px; max-width: 64px; font-size: 1.2rem; font-weight: 700; padding: 0 8px; order: 3;}
.notice_content .comment_input .input_bt {font-size: 1.2rem; font-weight: 350; letter-spacing: -0.03em; margin-top: 5px; order: 4;}
.notice_content .comment_view {margin: 40px 0 60px; color: #555;}
.notice_content .comment_view .accordion {max-height: 465px; overflow: hidden; transition: max-height .5s ease-in-out; -webkit-transition: max-height .5s ease-in-out;}
.notice_content .comment_view .accordion.open {max-height: 10000px; transition: max-height .7s ease-in-out; -webkit-transition: max-height .7s ease-in-out;}
.notice_content .comment_view li {margin: 20px 0;}
.notice_content .comment_view li:first-of-type {margin-top: 0;}
.notice_content .comment_view .del span {display: inline-block; width: 26px; height: 19px; background: url(../images/common/ico_comment.svg) no-repeat center center; vertical-align: text-top; margin-right: 5px; text-indent: -9999px; cursor: pointer;}
.notice_content .comment_view .btn_accordion {color: #111;}
.notice_content .comment_view .btn_accordion::after {position: initial; display: inline-block; margin-left: 15px;}

.prize_content .info > div {margin-bottom: 75px;}
.prize_content .info > div:last-of-type {margin-bottom: 0;}
.prize_content .info img {width: auto; max-width: 100%; margin: 0 auto; display: block;}
.prize_content .info .tit {font-size: 2.0rem; font-weight: 700; letter-spacing: -0.03em; line-height: 1.55; margin-top: 25px;}
.prize_content .info .tx_info {font-size: 1.6rem; font-weight: 350; letter-spacing: -0.03em; line-height: 1.56;}

/* AD Sprots list */
.ad_sports_content {margin-top: 25px;}
.ad_sports_content .tit {font-size: 3.0rem; font-weight: 350; letter-spacing: -0.05em;}
.ad_sports_content .tit .num {display: inline-block; font-size: 3.8rem; font-weight: 400; border-bottom: 1px solid #111; margin-left: 30px;}
.ad_sports_content .list {display: flex; align-items: flex-start; justify-content: space-between; flex-wrap: wrap; margin-top: 30px;}
.ad_sports_content .list li {width: calc(50% - 10px); letter-spacing: -0.03em; margin-bottom: 50px;}
.ad_sports_content .list li > img {margin-bottom: 20px;}
.ad_sports_content .list li > p {font-size: 2.0rem; font-weight: 700; line-height: 1.6;}
.ad_sports_content .list li > p span {font-weight: 900;}
.ad_sports_content .list li > span {font-size: 1.8rem; font-weight: 400; line-height: 1.5;}

/* Application */
.application_content .info .search_box {display: flex; align-items: center; justify-content: space-between;}
.application_content .info .search_box .tit {font-size: 1.8rem; font-weight: 300; letter-spacing: -0.01em;}
.application_content input[type="checkbox"] {border: 1px solid #aaa; width: 22px; height: 22px; cursor: pointer;}
.application_content input[type="checkbox"]:checked {background: url(../images/01_contest/ico_checked.png) no-repeat 0 0; border: none;}
.application_content .check_area {background: rgba(233, 233, 233, 0.67); padding: 30px 20px; margin-top: 30px;}
.application_content .check_area .tit {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.01em; width: 100%; margin-bottom: -25px;}

.application_content .check_area .table_type2 {display: flex; flex-direction: row; flex-wrap: wrap; }
.application_content .check_area .table_type2 table {width: calc(50% - 25px); font-size: 1.6rem; font-weight: 400; text-align: center; }
.application_content .check_area .table_type2 thead th {font-size: 1.2rem; color: #777; text-align: right; padding-bottom: 20px;}
.application_content .check_area .table_type2 table:nth-of-type(odd) {margin-right: 50px;}
.application_content .check_area .table_type2 tbody td:first-of-type {text-align: left;}
.application_content .check_area button {height: 22px; max-width: 70px; padding: 0 4px; font-size: 1.2rem; letter-spacing: -0.03em;}
.application_content .check_area .del {text-align: right;}
.application_content .check_area .del button {width: 22px; border-radius: 0; font-size: 19px;}
.application_content .btn_application {display: block; background: #fff; border: 5px solid #111; border-radius: 50%; width: 120px; height: 120px; font-size: 2.2rem; font-weight: 700; letter-spacing: -0.05em; line-height: 1.2; margin: 65px auto 30px; color: #111;}
.contest .application .sub_menu .tit {font-size: 2.6rem; font-weight: 500; letter-spacing: -0.05em; line-height: 1.7;}
.contest .application .poster {margin-top: 0;}

.payment .application_content {width: 100%;}
.payment .application_content .list {border-top: 1px dashed #111;}
.payment .application_content .list ul {border-bottom: 1px dashed #111; padding: 25px 0;}
.payment .application_content .list ul li {font-size: 1.6rem; font-weight: 400; color: #111; line-height: 1.5; padding: 5px 0;}
.payment .application_content .list ul .tit {display: inline-block; font-size: 1.4rem; font-weight: 500; color: #999; width: 15%;}
.payment .application_content .list ul .tit + span {width: calc(85% - 6px); display: inline-block; vertical-align: top;}

.payment .application_content .list ul .detail_box > .tit {display: none;}
.payment .application_content .list .detail_box {background: rgba(233, 233, 233, 0.67); margin: 15px 0; padding: 20px 20px; display: flex; flex-wrap: wrap;}
.payment .application_content .list .detail_box > div {width: calc(50% - 25px); margin: 10px 0;}
.payment .application_content .list .detail_box > div:nth-of-type(even){margin-left: 50px;}
.payment .application_content .list .detail_box > div > p + p {margin-top: 5px;}
.payment .application_content .list .detail_box > div .first_line {color: #333; display: flex; justify-content: space-between;}
.payment .application_content .list .detail_box > div .first_line span {width: 30%;}
.payment .application_content .list .detail_box > div .first_line span:first-of-type {font-weight: 700;}
.payment .application_content .list .detail_box > div .pay1 {text-align: right; font-weight: 700;}
.payment .application_content .list .detail_box > div .tx_type05 {font-size: 1.4rem;}
.payment .application_content .list .pay2 {font-size: 2.0rem; font-weight: 700; letter-spacing: -0.01em;}
.payment .application_content .list .pay3 {font-size: 2.4rem; font-weight: 700; letter-spacing: -0.01em;}
.payment .application_content .list ul .tx_type06 {color: #111; font-weight: 500;}
.payment .application_content .list .deduct {display: flex; align-items: center; justify-content: flex-start; padding: 20px 0 10px;}
.payment .application_content .list .deduct > div span {font-size: 1.6rem; vertical-align: middle;}
.payment .application_content .list .deduct input {background: #fff; border: 1px solid #aaa; height: 28px; max-width: 70px; text-align: center; font-size: 1.6rem; color: #111; letter-spacing: -0.01em;}
.payment .application_content .list .deduct button {padding: 6px 8px;}

.payment .application_content .tx_type07 {margin-top: 35px;}
.tx_type07 {font-size: 1.5rem; font-weight: 400;}
.payment .application_content .btn_application.okay {background: #111; color: #fff; margin-top: 38px;}

/******************** Ground ********************/
.ground .list_type1 .bar .tx_type03 {flex-grow: 2;}
.ground .list_type1 .search_box {flex-grow: 0.5;}
.ground .list_type1 .bar {border-bottom: none;}

.ground .table_type1 tbody td {font-weight: 300; font-size: 1.6rem;}
.ground .table_type1 thead th:first-of-type {text-align: left;}
.ground .table_type1 tbody td:first-of-type  {text-align: left; font-weight: 500;}
.ground .table_type1 tbody td:last-of-type {text-align: right;}

.ground .paging {margin-bottom: 40px;}

.ground .ground_section {padding-top: 55px; margin-bottom: 115px;}
.ground_section h2 {font-size: 2.0rem; font-weight: 700; color: #333;}

.ground_content {width: 100%;}
.ground_content ul {margin-top: 20px;}
.ground_content ul li {font-weight: 500; color: #333; display: flex; justify-content: flex-start; align-items: flex-start; padding: 12px 0;}
.ground_content ul li p {font-size: 1.4rem; flex-grow: 0; width: 15%;}
.ground_content ul li span {display: block; font-size: 1.6rem; flex-grow: 1; width: 85%;}

.ground_section .map {width: 100%; background-color: #f1f1f1; margin-top: 65px; overflow: hidden;}

/* ground slide */
.ground_section .slide {margin-top: 0; margin-right: 50px; position: relative;}
.ground_section .slide .slide_box {width: 320px; height: 450px; margin-top: 0; overflow: hidden; }
.ground_section .slide .slide_box .silde01 img {max-width: 100%; border-radius: 0; margin-bottom: 0;}
.ground_section .slide .dots {position: absolute; left: 0; right: 0; bottom: -27px;}
.ground_section .slide .dots .dot {width: 8px; height: 8px; margin: 0 4px;}

/******************** Event ********************/
.event .list_type1 .bar {border-bottom: none;}
.event .list_type1 .search_box {flex-grow: 0;}
.event .list_type1 input, .event .list_type1 select {border-radius: 0; margin-right: 5px;}
.event .list_type1 input {width: 160px;}
.event .list_type1 select {width: 100px;}
.event .list_type1 .search_box button {padding: 6px 8px; min-width: 50px;}

.event_list .table_type1 tbody td:nth-of-type(-n+2), .event_list .table_type1 thead th:nth-of-type(-n+2) {text-align: left; font-size: 1.6rem; font-weight: 500; color: #111;}
.event_list .table_type1 tbody .date {color: #aaa;}

.event .event_contents {border-top: 3px solid #111; margin-top: 20px; display: flex; justify-content: flex-start; align-items: flex-start; padding-top: 45px;}
.event_contents .left_list {margin-right: 50px; width: 320px; min-width: 320px;}
.event_contents .left_list .search_box {display: flex; justify-content: flex-start; margin-top: 7px;}
.event_contents .left_list .search_box select {width: 100px; margin-right: 5px;}
.event_contents .left_list .search_box input {background: #fff; border: 1px solid #aaa; padding: 5px; font-size: 1.2rem; letter-spacing: 0; line-height: 1.2; margin-right: 5px; width: 160px;}
.event_contents .left_list .search_box button {padding: 6px 8px; min-width: 50px;}

.event_contents .left_list ul {width: 100%; margin-top: 40px;}
.event_contents .left_list ul li {font-size: 1.6rem; font-weight: 400; display: flex; margin: 15px 0;}
.event_contents .left_list ul li span {overflow: hidden;}
.event_contents .left_list ul li span:first-of-type {width: 80%; text-overflow: ellipsis; white-space: nowrap; padding-right: 10px;}
.event_contents .left_list ul li .date {font-size: 1.2rem; color: #aaa; width: 20%;}

.event .notice_content {max-width: 730px;}
.event .title {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; padding: 6px 0; line-height: 1.4;} 
.event .title h2 {font-size: 2.0rem; font-weight: 700; color: #333;}
.event .tx_type04 {font-size: 1.4rem; font-weight: 400; letter-spacing: -0.01em; line-height: 1.45;}

.event .event_contents .img_info {border-bottom: 1px dashed #111; padding-bottom: 60px;}
.event .event_contents .img_info > div {margin-top: 30px; text-align: justify;}
.event .event_contents .img_info > div + img {margin-top: 60px;}
.event .event_contents .img_info .text_type1 {font-size: 3.0rem; font-weight: 500; letter-spacing: -0.05em; margin-bottom: 15px;}
.event .event_contents .img_info .text_type2 {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.03em; margin-bottom: 15px;}
.event .event_contents .img_info .text_type3 {font-size: 1.6rem; font-weight: 400;}
.event .event_contents .paging_arrow {margin-bottom: 100px;}

/******************** CS Notice ********************/
.cs_notice .cs_notice_list {border-top: 3px solid #111; margin-top: 20px; display: flex; justify-content: flex-start; align-items: flex-start; padding-top: 45px;}
.cs_notice_list .left_text {margin-right: 50px; width: 320px; min-width: 320px; font-size: 3.4rem; font-weight: 100; letter-spacing: -0.05em;}
.cs_notice_list .notice_content {max-width: 730px;}
.cs_notice_list .notice_content .info {margin-top: 0;}

/******************** list type ********************/
.list_type1 {margin-top: 55px; border-top: 3px solid #111;}
.list_type1 .bar {display: flex; justify-content: space-between; align-items: center; margin-top: 45px; border-bottom: 1px dashed #111; padding-bottom: 35px;}
.list_type1 .bar .tx_type03 {font-size: 2.0rem; font-weight: 400; letter-spacing: -0.03em; flex-grow: 0.5;}
.list_type1 select {border-radius: 5px; min-width: 95px; width: 95px;}
.list_type1 input {background: #fff; border: 1px solid #aaa; border-radius: 5px; padding: 5px; font-size: 1.2rem; letter-spacing: 0;line-height: 1.2;}
.list_type1 .search_box {display: flex; justify-content: flex-start; flex-grow: 1.5; margin-left: 40px;}
.list_type1 .search {position: relative; margin-left: 6px; max-width: 115px;}
.list_type1 .search input {width: 100%;}
.list_type1 .search label {position: absolute; right: 10px; top: calc(50% - 9px); font-size: 1.2rem; font-weight: 700;}
.list_type1 .list_date a {font-size: 1.2rem; font-weight: 400; color: #333; margin: 0 3px;}
.list_type1 .list_date .active {color: #111; font-weight: 700;}
.list_type1 ul.list {padding: 40px 0 45px; border-bottom: 1px dashed #111;}
.list_type1 ul.list li {margin-bottom: 30px; font-size: 1.4rem; font-weight: 500; letter-spacing: -0.03em; line-height: 1.2; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; cursor: pointer;}
.list_type1 ul.list li:last-of-type {margin-bottom: 0;}
.list_type1 ul.list li b {padding-right: 10px;}
.list_type1 ul.list li b > span {font-weight: 900;}
.list_type1 ul.list li > span {display: inline-block; padding-left: 10px;}
.list_type1 ul.list .end {color: #777;}
.list_type1 ul.list .bg {background: rgba(233, 233, 233, 0.6667);}

.contest .list_type1 select, .ground .list_type1 select {border-radius: 0;}
.contest .list_type1 input, .ground .list_type1 input {border-radius: 0; max-width: 115px; margin-left: 5px;}
.contest .list_type1 button, .ground .list_type1 button {font-size: 1.2rem; font-weight: 500; background: #888; color: #fff; padding: 6px 5px; border-radius: 5px; line-height: 1.2; height: 28px; margin-left: 5px; min-width: 50px;}

/******************** table type ********************/
table {width: 100%; table-layout: fixed;}
.table_type1 {border-bottom: 1px dashed #111;}
.table_type1 thead th  {font-size: 1.2rem; font-weight: 400; line-height: 1.5; color: #aaa; padding-bottom: 10px; border-bottom: 1px dashed #111;}
.table_type1 tbody td {font-size: 1.4rem; font-weight: 400; line-height: 1.5; text-align: center; color: #333; padding: 12px 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.table_type1 .num {font-size: 1.2rem;}
.table_type1 .info {text-align: left;}
.table_type1 .point {font-size: 1.6rem; font-weight: 700;}
.table_type1 tbody .info {font-weight: 700; color: #111;}


@media (min-width: 769px) and (max-width: 1024px) {
  .list_type1 .bar {flex-flow: wrap;}
  .list_type1 .search label {top: calc(50% - 6px);}
  .my_profile .photo {margin-right: 10vw;}
  .contents .sub_title2 {font-size: 3.2rem; min-width: 12vw;}
  .contents .sub_title2 span {font-size: 2.6rem;}
  .application_content .info .search_box {flex-direction: column; align-items: flex-start; justify-content: flex-start;}
  .application_content .info .search_box .tit {margin-bottom: 15px;}
  .application_content .check_area .table_type2 table {width: 100%;}
  .application_content .check_area .table_type2 table:nth-of-type(odd) {margin-right: 0;}
  .application_content .check_area .table_type2 thead.pc {display: none;}
  .payment .application_content .list .detail_box > div {width: 100%;}
  .payment .application_content .list .detail_box > div:nth-of-type(even) {margin-left: 0;}
}