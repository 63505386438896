@charset "UTF-8";

@import url('font.css');

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
	padding: 0
}


html {font-size: 62.5%; overflow-x: hidden; min-width: 360px; width: 100%; height: 100%;}
body {background: #fff; word-break: break-all; font-family: 'Noto Sans KR'; color: #111; width: 100%; height: auto;}
ol, ul, li {list-style: none}
img, button {border: none; vertical-align: top;}
img {height: auto; object-fit:contain; -webkit-object-fit:contain; width: 100%;}
button {outline: 0;}
label, button {cursor: pointer}
input, button {border-radius: 0; appearance: none; -webkit-appearance: none; -webkit-border-radius: 0;}
caption, legend {width: 0; height: 0; margin: 0; padding: 0; text-indent: -9999em; overflow: hidden; font-size: 0;}
select {-webkit-appearance:none; -moz-appearance:none; appearance:none;}
/* input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button {appearance: none; visibility: hidden; z-index: 10; width: 100px;} */

section {transition: all 0.4s ease-in-out; -webkit-transition: all 0.4s ease-in-out;}

a {color: #111; text-decoration: none; outline: 0;}
a:hover {color: #111;}
a:visited,
a:focus,
a:active {color: #111;}

input, textarea, select {font-size: 1.2rem; vertical-align: middle; outline: 0;}
::before, ::after {box-sizing: border-box; -webkit-box-sizing: border-box;}

.ellipsis {overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}

.t_bold {font-weight: 700;}
.t_ct {text-align: center;}
.t_l {text-align: left;}
.t_r {text-align: right;}

header, footer, .contents {max-width: 1100px; margin: 0 auto; transition: all 0.4s ease-in-out; -webkit-transition: all 0.4s ease-in-out;}

/******************** input ********************/
input[type="text"], input[type="password"], input[type="number"], input[type="date"] {background: #E9E9E9; border: none;font-size: 1.4rem; letter-spacing: -0.05em; font-weight: 400; color: #333;}
input::placeholder {color: #333;}
select {border: 1px solid #aaa; font-size: 1.5rem; font-weight: 400; color: #333; line-height: 1.2; padding: 5px; min-width: 100px; background: url(../images/common/ico_select.svg) no-repeat 95% center;}
select + select {margin-left: 5px;}

/******************** btn ********************/
.dots {display: flex; justify-content: center;}
.dots .dot {text-indent: -9999px; border-radius: 50%; background: #E0E0E0; opacity: .9; cursor: pointer;}
.dots .dot.dot_active {background: #111; opacity: 1;}

.btn_r_black {display: inline-block; background: #000; border-radius: 15px; font-size: 1.3rem; letter-spacing: -0.03em; font-weight: 700; padding: 4px 7.5px 5px;}
.btn_type1 {border-radius: 5px; background: #111; color: #fff; text-align: center; height: 34px; padding: 0 10px;}
.btn_type2 {border: 1px solid #333; border-radius: 5px; background: #fff; color: #333; text-align: center;}
.btn_type3 {font-size: 1.2rem; font-weight: 500; background: #777; color: #fff; padding: 6px 5px; border-radius: 5px; line-height: 1.2; min-height: 28px;}

.btn_accordion {font-size: 1.6rem; font-weight: 350; letter-spacing: -0.03em; line-height: 1.5; width: 100%; text-align: center; background: #e9e9e9; padding: 16px 30px; position: relative; cursor: pointer;}
.btn_accordion::after {content: ""; display: block; clear: both; width: 22px; height: 14px; background: url(../images/01_contest/ico_accordion.svg) no-repeat right center; position: absolute; top: calc(50% - 7px); right: 20px; transition: all .5s ease-in-out;}
.btn_accordion.open::after { background: url(../images/01_contest/ico_accordion.svg) no-repeat right center; transform: rotate(180deg); transition: all .5s ease-in-out;}

/******************** paging ********************/
.paging {text-align: center; margin-top: 40px;}
.paging a {font-size: 1.6rem; font-weight: 300; letter-spacing: -0.03em; display: inline-block; margin: 0 13px;}
.paging .on {font-weight: 700; border-bottom: 1px solid #111;}

.paging_arrow {display: flex; justify-content: center; align-items: center; padding-top: 30px; border-top: 1px dashed #111; }
.paging_arrow a {display: inline-block;  font-size: 1.6rem; font-weight: 300; letter-spacing: -0.03em;}
.paging_arrow .prev {background: url(../images/01_contest/paging_arrow2.svg) no-repeat 0 0; width: 11px; height: 19px; margin-right: 85px; transform: rotate(180deg);}
.paging_arrow .prev.end {background: url(../images/01_contest/paging_arrow1.svg) no-repeat 0 0; transform: rotate(0);}
.paging_arrow .next {background: url(../images/01_contest/paging_arrow2.svg) no-repeat 0 0; width: 11px; height: 19px; margin-left: 85px;}
.paging_arrow .next.end {background: url(../images/01_contest/paging_arrow1.svg) no-repeat 0 0; transform: rotate(180deg);}

/******************** header ********************/
header {padding: 40px 0 30px; display: flex; align-items: center; justify-content: space-between;} 
header .logo a {display: inline-block;}
header .top_right {display: flex; flex-direction: row; align-items: center; z-index: 10;}

.gnb {font-size: 1.8rem; font-weight: 500; letter-spacing: -0.03em;}
.gnb a {margin-right: 68px;}
.gnb a:last-of-type {margin-right: 58px;}

header .search {display: flex; align-items: center;}
header .search input[type="text"] {background: #E9E9E9; border: none; border-radius: 20px; opacity: 0.99px; width: 151px; height: 26px; font-size: 1.4rem; padding: 0 10px;}
header .search input + label {background: url(../images/common/ico_search.svg) no-repeat 0 0; width: 25px; height: 26px; margin-left: 4px; display: inline-block;}

header .profile {margin-left: 10px; width: 30px; height: 30px; text-align: center; overflow: hidden; cursor: pointer;}
header .profile.default {background:url(../images/common/ico_profile.svg) no-repeat 50% 50%; background-size: 25px 28px;}
header .profile img {border-radius: 50%;}

.mo_gnb {display: none;}

/******************** footer ********************/
footer {border-top: 3px solid #111; padding: 55px 0 38px; display: flex; flex-direction: row; align-items: center;}
footer img {max-width: 180px; margin-right: 40px;}
footer .btn_top {display: block; text-indent: -9999px; margin-right: 30px; width: 31px; height: 31px; background: url(../images/common/ico_top.svg) no-repeat center center; min-width: 31px;}
footer .copyright {font-size: 1.3rem; letter-spacing: -0.02em; font-weight: 350; line-height: 23.4px; color: #000;} 

/******************** login ********************/
.contents.login {width: 100%; text-align: center; margin: 80px auto 0;}
.contents.login h1, .contents.join h1 {font-size: 3.2rem; letter-spacing: -0.03em; font-weight: 100; margin-bottom: 12px;}
.contents .tx_type02 {font-size: 2.2rem; font-weight: 300; letter-spacing: -0.03em; margin: 70px 0 40px;}
.mine {margin-top: 10px; font-size: 15px;}

/* 기본로그인 */
.default_login {margin-bottom: 30px;}
.default_login .form {display: flex; flex-direction: column; align-items: center;}
.default_login .form input {width: 360px; height: 34px; text-align: center; margin-bottom: 10px;}
.default_login .btn_type1 {width: 360px; height: 42px; padding: 0 20px; font-size: 1.8rem; font-weight: 700;}

/* 아이디 비번 찾기 */
.contents.find {width: 100%; text-align: center; margin: 80px auto 0;}
.contents.find h1, .contents.find h1 {font-size: 3.2rem; letter-spacing: -0.03em; font-weight: 100; margin-bottom: 12px;}
.contents .tx_type02 {font-size: 2.2rem; font-weight: 300; letter-spacing: -0.03em; margin: 70px 0 40px;}

/*  기본 회원 찾기 */
.default_find {margin-bottom: 30px;}
.default_find .form {display: flex; flex-direction: column; align-items: center;}
.default_find .form input {width: 360px; height: 34px; text-align: center; margin-bottom: 10px;}
.default_find .btn_type1 {width: 360px; height: 42px; padding: 0 20px; font-size: 1.8rem; font-weight: 700;}
.find_id {margin-top: 10px; font-size: 18px; font-weight: bold;}
.find_pwd {margin-top: 10px; font-size: 18px; font-weight: bold;}

/* sns 계정 로그인 */
.sns_login {border-top: 1px dashed #111; padding-top: 40px; margin-bottom: 40px;}
.contents.login h2 {font-size: 1.4rem; font-weight: 700; letter-spacing: -0.05em; color: #333; margin-bottom: 20px;}
.sns_login a {display: inline-block; margin: 0 8px;}
.sns_login img {object-fit: none; width: auto;}
.sns_login .btn_type2 {display: block; margin: 25px auto 0; width: 360px; height: 42px; padding: 0 20px; font-size: 1.8rem; font-weight: 700;}

/******************** join ********************/
.contents.join {width: 100%; margin: 45px auto 0;}
.contents.join h1 {text-align: center; margin-bottom: 25px;}
.contents.join section {display: flex; flex-direction: row; justify-content: center; align-items: flex-start; border-bottom: 1px dashed #111; padding: 0 130px 30px 0;}
.contents.join section:last-of-type {border-bottom: none;}
.contents.join section + section {margin-top: 40px;}
.contents.join section .title {font-size: 1.2rem; font-weight: 700; letter-spacing: -0.02em;  margin-right: 34px; min-width: 96px; line-height: 2.5; text-align: right;}
.contents.join .form {max-width: 360px;}
.contents.join input {height: 34px; padding: 0 6px; vertical-align: top;}
.contents.join input[type="text"] + label, .contents.join input[type="number"] + label, .contents.join input[type="date"] + label {background: #111; color: #fff; font-size: 1.4rem; font-weight: 500; letter-spacing: -0.03em; border-radius: 5px; display: inline-flex; justify-content: center; align-items: center; min-width: 75px; height: 34px; margin-left: 4px; padding: 5px;}
/* 로그인정보 */
.login_info .form div p {font-size: 1.2rem; font-weight: 400; letter-spacing: -0.02em; margin-top: 10px;}
.join .form .id input, .join .form .nickname input {width: 277px;}
.join .nickname {margin-top: 10px;}
.join .password {margin-top: 20px;}
.join .password input {margin-bottom: 10px; width: 360px;}
.join .password input:last-of-type {margin-bottom: 0;}
/* 프로필정보 */
.join .name {display: flex; justify-content: space-between;}
.join .name input[type="text"] {width: 200px;}
.join .gender {font-size: 1.4rem; font-weight: 400; letter-spacing: -0.05em; align-self: center; margin-left: 10px; display: flex; align-items: center;}
.join input[type="radio"] {width: 14px; height: 14px; border: 2px solid #999; border-radius: 50%; margin: 0 5px 0 10px; cursor: pointer;}
.join input[type="radio"]:checked {background-color: #999; box-shadow: 0 0 0 2px #fff inset;}
.join input[type="date"]::before {content: attr(data-placeholder); width: 100%;}
.join input[type="date"]:focus::before,
.join input[type="date"]:valid::before {display: none;}

.profile_info .form > div:not(.name) input {width: 277px;}
.profile_info .form > div {margin-bottom: 10px;}
.profile_info .form input[type="text"] + label + input[type="text"] {margin-top: 10px; width: 360px;}
.profile_info .form .photo {margin-bottom: 20px; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;}
.profile_info .form .photo dt {width: 200px; height: 200px; background-color: #E9E9E9; overflow: hidden;}
.profile_info .form .photo dd {font-size: 1.4rem; font-weight: 400; letter-spacing: -0.03em; color: #333; margin-left: 30px; width: 130px; line-height: 1.5;}
.profile_info .form .photo .btn_type1 {min-width: 130px; margin-top: 10px; display: flex; justify-content: center; align-items: center;}
/* 관심지역 및 종목 */
.area_event .form .area {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.05em; line-height: 1.2; display: flex; align-items: center; justify-content: flex-start;}
.area_event .form .area select {margin-left: 5px;}
.area_event .form .event {margin-top: 30px;}
.area_event .form .event > p {font-size: 1.8rem; font-weight: 700; letter-spacing: -0.05em; line-height: 1.2;}
.area_event .form .event > div {display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start; margin-top: 30px; max-width: 650px;}
.area_event .form .event > div button {width: 60px; height: 60px; text-align: center; padding: 0; background: #f1f1f1; border-radius: 5px; font-size: 1.4rem; letter-spacing: -0.05em; font-weight: 500; line-height: 1.4; margin: 0 5px 5px 0; color: #111;}
.area_event .form .event > div button.active {background: #111; color: #fff;}
.area_event .form .event > div button p {font-size: 2.0rem; font-weight: 700;}

.contents.join section.area_event {padding-right: 80px; justify-content: flex-end;}
.contents.join .area_event .form {max-width: 100%;}
.contents.join .tx_type02 {margin-top: 54px;}

.join .btn_type1.complete {min-width: 320px; height: 48px; margin: 44px 35px 0 0; font-size: 2.4rem; font-weight: 300; letter-spacing: -0.05em; background: url(../images/common/ico_profile3.svg) no-repeat 15% center #000; position: relative;}
.join .btn_type1.complete::after {content: ""; display: inline-block; clear: both; width: 8px; height: 12px; background: url(../images/common/mo/ico_w_arrow.svg) no-repeat 0 0; position: absolute; right: 13px; top: calc(50% - 6px);}

/******************** login 이후 ********************/
.my_schedule {margin-top: 54px; overflow: hidden;}
.my_info {border-bottom: 0.5px dashed #111; padding-bottom: 35px; display: flex; justify-content: flex-start; align-items: flex-start;}
.my_info > div {margin-right: 4.5vw;}
.my_info > div:last-of-type {margin-right: 0;}
.my_info .data {font-size: 1.6rem; font-weight: 350; line-height: 23px; letter-spacing: -0.03em; max-width: 317px; width: 35%;}

/* tab */
.my_info .choice {width: 75%;}
.my_info .choice .tx_tit02 {font-size: 1.2rem; font-weight: 700; letter-spacing: -0.03em;}
.my_info .choice .tab {display: flex; align-items: center; justify-content: flex-start; width: 100%;}
.my_info .choice .tab_title::after {content: ""; display: inline-block; clear: both; background: url(../images/common/ico_arrow_b.svg) no-repeat 0 0; background-size: contain; width: 1.586vw; height:  1.322vw; margin-left: 0.5vw; max-width: 24px; max-height: 20px;}
.my_info .choice .tab_title a {font-size: 2.0rem; font-weight: 300; letter-spacing: -0.05em; margin-right: 6px;}
.my_info .choice .tab_title a:last-of-type {margin-right: 0;}
.my_info .choice .tab_title .active {font-weight: 700; border-bottom: 1px solid #111;}

.my_info .contest.active {display: flex;}
.my_info .contest {display: flex; padding: 5px 0 5px 30px; flex-wrap: wrap; display: none;}
.my_info .contest > div::after {content: "/"; display: inline-block; clear: both; width: 5px; font-size: 2.0rem; margin-right: 5px;}
.my_info .contest > div:last-of-type:after {display: none;}
.my_info .contest span {font-size: 2.0rem; letter-spacing: -0.05em; font-weight: 400;}
.my_info .contest span + span {font-weight: 700;} 

.my_contest {border-bottom: 0.5px dashed #111; padding: 35px 0; display: flex; justify-content: space-between; align-items: flex-start;}
.my_contest > div {margin-right: 10px; width: 33.33%; max-width: 317px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.my_contest > div:last-of-type {margin-right: 0;}
.my_contest .list p {font-size: 1.2rem; font-weight: 700; letter-spacing: -0.03em; margin-bottom: 12px; min-height: 26px; display: flex; justify-content: space-between; align-items: center;}
.my_contest .list p span {display: inline-block;}
.my_contest .list a {color: #fff;}
.my_contest .list ul li {font-size: 1.6rem; font-weight: 400; letter-spacing: -0.03em; line-height: 24px; padding: 5px 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.my_contest .list ul li:last-of-type {padding-bottom: 0;}
.my_contest .list ul li b {font-weight: 900;}

/* slide list */
.slide_list {margin-top: 50px; overflow: hidden; position: relative;}
.slide_list .title_box {display: flex; align-items: center; justify-content: space-between;}
.slide_list .title_box .tit {font-size: 2.6rem; font-weight: 350; letter-spacing: -0.05em;}
.slide_list .ico_star {background: url(../images/common/ico_star.png) no-repeat top right; padding-right: 20px;}
.slide_list .dots {position: absolute; top: 10px; right: 130px;}
.slide_list .dots .dot {width: 14px; height: 14px; margin: 0 17px;}
.slide_box {display: flex; flex-direction: row; margin-top: 15px;}
.slide_box .silde01 {display: flex; flex-direction: row; align-items: flex-start;}
.slide_box .silde01 > div {font-size: 1.6rem; font-weight: 400; color: #333; width: 25%; max-width: 260px; margin-right: 20px;height:100%}
.slide_box .silde01 > div:last-of-type {margin-right: 0;}
.slide_box .silde01 img {margin-bottom: 11px; border-radius: 5px; max-width: 260px; height: 100%;object-fit: cover;}
.slide_list .title_box a {color: #fff;}

/******************** sub title ********************/
.contents .sub_title {font-size: 2.4rem; font-weight: 350; letter-spacing: -0.05em; margin-top: 25px; background: url(../images/common/ico_arrow_g.svg) no-repeat left center; padding-left: 30px;}
.contents .sub_title2 {font-size: 3.8rem; font-weight: 100; letter-spacing: -0.03em; margin-right: 20px; min-width: 372px;}
.contents .sub_title2 span {font-size: 3.0rem;}
.contents .sub_title3 {font-size: 3.8rem; font-weight: 100; letter-spacing: -0.03em;}

/* popup */
.dim_bg {position: fixed; width: 100%; height: auto; touch-action:none;}
.dim_layer {width: 100%; height: 100%; background: rgba(0, 0, 0, .6); z-index: 100; position: fixed; left: 0; top: 0;}
.popup_layer {position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: #fff; border-radius: 15px; width: 100%; max-width: 730px; height: 800px; padding: 45px 55px; text-align: center; margin: auto;}
.popup_layer .btn_close {display: flex; justify-content: flex-end;}
.popup_layer .btn_close_black {display: flex; justify-content: flex-end;}
.popup_layer .btn_close a {display: block; background: url(../images/common/ico_close.svg) no-repeat center center; height: 17px; width: 18px; text-indent: -9999px;}
.popup_layer .btn_close_black a {display: block; background: url(../images/common/ico_close_black.svg) no-repeat center center; height: 17px; width: 18px; text-indent: -9999px;}
.popup_layer .tit {font-size: 2.4rem; font-weight: 500; letter-spacing: -0.03em; line-height: 1.25; color: #333;}
.popup_layer .tit .bt_line {border-bottom: 1px solid #333;}
.popup_layer > p {font-size: 1.4rem; font-weight: 400; color: #888; margin-top: 15px; line-height: 1.25;}
.popup_layer textarea {width: 100%; height: 100%; max-height: 520px; border: 1px solid #999; margin-top: 15px; font-size: 1.4rem;}
.popup_layer .btn_type1 {font-size: 1.6rem; font-weight: 700; letter-spacing: -0.03em; height: 36px; padding: 0 7px; min-width: 144px; margin-top: 45px;}

.popup_layer .bt_area {position: relative;}
.popup_layer .bt_area .num {position: absolute; left: 0; bottom: 9px; display: inline-block; font-size: 1.4rem; font-weight: 400; color: #555;}
.popup_layer .popupCheck input[type="checkbox"] {border: 2px solid #000; width: 22px; height: 22px; cursor: pointer;}
.popup_layer input[type="checkbox"]:checked {background: url(../images/01_contest/ico_checked.png) no-repeat 0 0; border: none;}
.popup_layer input[type="radio"] {border: 2px solid #000; background-color: #fff; width: 17px; height: 17px; border-radius: 50%;}
.popup_layer input[type="radio"]:checked {background-color: #000; border: 2px solid #000; box-shadow: 0 0 0 3px #fff inset;}
.popup_layer .search_box {display: flex; justify-content: space-between; align-items: center; margin-top: 20px;}
.popup_layer .search_box label {font-size: 1.4rem; font-weight: 400; margin-left: 5px;}
.popup_layer .search_box input[type="radio"] + label {margin-right: 20px; vertical-align: middle;}
.popup_layer .search_box input[type="text"] {border: 1px solid #aaa; background: #fff; min-width: 225px; max-width: 100%; height: 28px; padding: 0 5px;}
.popup_layer .search_box .btn_type3 {padding: 6px 8px; margin-left: 5px;}

.popup_layer .table_type1 {margin-top: 15px; max-height: 472px; overflow: hidden; overflow-y: auto;}
.popup_layer .table_type1 tbody td {text-align: left; padding: 8px 0; color: #111; font-size: 1.6rem;}
.popup_layer .table_type1 tbody td:last-of-type {text-align: right;}

@media (min-width: 769px) and (max-width: 1024px) {
  html {font-size: 50%;}
  body {padding: 0 20px;}
  .gnb a {margin-right: 20px;}
  .gnb a:last-of-type {margin-right: 20px;}
  .my_contest > div {margin-right: 4.5vw;}
  .slide_list .dots {right: 10vw;}
}
@media (min-width: 1025px) and (max-width: 1109px) {
  html {font-size: 58%;}
  body {padding: 0 20px;}
}
@media all and (min-width:1100px) {
  .my_info .data {margin-right: 74px;}
}
@media all and (min-width:769px) {
  .mo {display: none;}
}