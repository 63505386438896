/* Sidebar 기본 스타일 */
.admin_sidebar {
    width: 240px;
    height: 100vh;
    background-color: #333;
    color: white;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

/* 로고 스타일 
.logo img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
}
    */

/* 각 토글 버튼 스타일 */
.user_toggle_button, .ground_toggle_button, .system_toggle_button {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.user_toggle_button:hover, .ground_toggle_button:hover, .system_toggle_button:hover {
    background-color: #555;
}

/* 메뉴 기본 스타일 (보일 때) */
.user_menu.open,
.ground_menu.open,
.system_menu.open {
    margin-bottom: 20px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: 500px; /* 열렸을 때의 최대 높이 */
    opacity: 1;
    
}

/* 메뉴가 닫혔을 때 스타일 */
.user_menu.closed,
.ground_menu.closed,
.system_menu.closed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}

/* 메뉴 리스트 스타일 */
.user_menu ul,
.ground_menu ul,
.system_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user_menu ul li,
.ground_menu ul li,
.system_menu ul li {
    margin-bottom: 10px;
}

.user_menu ul li a,
.ground_menu ul li a,
.system_menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 8px 10px;
    display: block;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.user_menu a:visited,
.ground_menu a:visited,
.system_menu a:visited {
    color: white;
}

.user_menu ul li a:hover,
.ground_menu ul li a:hover,
.system_menu  ul li a:hover {
    background-color: #555;
}