.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    /* font-family: Arial, sans-serif; */
  }
  
  .button-container {
    display: flex;
    flex-direction: row; /* 변경된 부분: column에서 row로 */
    gap: 20px; /* 버튼 사이 간격 유지 */
    margin-top: 20px;
  }
  
  .verification-button {
    padding: 15px 30px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .verification-button:hover {
    background-color: #000000;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .verification-button {
        padding: 10px 20px;
        font-size: 17px;
    }
  }
  
  @media (max-width: 480px) {
    .button-container {
        flex-direction: column; /* 작은 화면에서는 다시 버튼을 세로로 배열 */
    }
    .verification-button {
        padding: 8px 15px;
        font-size: 15px;
    }
  }