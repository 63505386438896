@charset "UTF-8";
@media handheld, only screen and (max-width: 768px) {
/******************** common ********************/
html {font-size: 62.5%; overflow-x: hidden;}
header, .visual, .my_info > div, .my_contest .list, .slide_list, .notice, .schedule {padding-left: 20px; padding-right: 20px;}

header {padding-top: 15px;}
header .logo {position: absolute; top: auto; left: 0; width: 100%; text-align: center; z-index: 0; min-width: 360px;}
header .logo img {object-fit: none;}
header .search {display: none;}
header .ico_search {background: url(../images/common/ico_search.svg) no-repeat 0 0; width: 25px; height: 26px; margin-left: 4px; cursor: pointer;}
header .profile {margin-left: 5px;}

/* btn */
.btn_type1.arrow, .btn_type2.arrow {position: relative;}
.btn_type1.arrow::after {content: ""; display: inline-block; clear: both; width: 8px; height: 12px; background: url(../images/common/mo/ico_w_arrow.svg) no-repeat 0 0; position: absolute; right: 13px; top: calc(50% - 6px);}
.btn_type2.arrow::after {content: ""; display: inline-block; clear: both; width: 8px; height: 12px; background: url(../images/common/mo/ico_b_arrow.svg) no-repeat 0 0; position: absolute; right: 13px; top: calc(50% - 6px);}

/* mobile menu */
header .mo_gnb  {position: relative; display: block; height: 17px; z-index: 10;}
header .mo_gnb::after {content: ""; display: block; clear: both;}
header .mobile_menu_btn, header .mobile_menu_btn span {display : inline-block; transition : all .8s;}
header .mobile_menu_btn {width: 19px; height: 17px;}
header .mobile_menu_btn span {position: absolute; left: 0; width: 100%; height: 3px; background-color: #777;}
header .mobile_menu_btn span:nth-of-type(1) {top : 0;}
header .mobile_menu_btn span:nth-of-type(2) {top : 7px;}
header .mobile_menu_btn span:nth-of-type(3) {bottom : 0;}
header .mobile_menu_btn.active {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
header .mobile_menu_btn.active span:nth-of-type(1) {-webkit-transform: translateY(20px) rotate(-45deg); transform: translateY(7px) rotate(-45deg);}
header .mobile_menu_btn.active span:nth-of-type(2) {opacity: 0;}
header .mobile_menu_btn.active span:nth-of-type(3) {-webkit-transform: translateY(-20px) rotate(45deg); transform: translateY(-7px) rotate(45deg);}

/* mobile menu open */
html.mobile_menu_open {overflow: hidden; position: fixed;}
html.mobile_menu_open .mo_gnb {max-height: none;}
html.mobile_menu_open .mobile_menu_list {transform: translateX(0);}
.mo_gnb .mobile_menu_list {background: #fff; width: 100%; height: calc(100% - 40px); z-index: 100; text-align: center; padding: 70px 20px 25px; transform: translateX(-100%); transition: transform .6s ease-in-out; position: fixed; overflow-y: auto; margin-left: -20px; margin-top: 10px;}
.mo_gnb .mobile_menu_list > a {display: block; width: 100%; font-size: 2.0rem; font-weight: 500; letter-spacing: -0.03em; margin-top: 50px;}
.mo_gnb .mobile_menu_list > a:first-of-type {margin-top: 0;}
.mo_gnb .mobile_menu_list .sub {margin-top: 10px;}
.mo_gnb .mobile_menu_list .sub a {display: inline-block; font-size: 1.4rem; font-weight: 350; letter-spacing: -0.05em; margin: 0 7px;}
.mo_gnb .mobile_menu_list .sub2 {margin-top: 50px;}
.mo_gnb .mobile_menu_list .sub2 a {font-size: 1.6rem; font-weight: 500; letter-spacing: -0.05em; margin: 0 7px;}
.mo_gnb .mobile_menu_list .copyright {width: 100%; margin-top: 85px;}
.mo_gnb .mobile_menu_list .copyright p {font-size: 1.27rem; font-weight: 300; letter-spacing: -0.03em;}
.mo_gnb .mobile_menu_list .copyright span {font-size: 1.0rem; font-weight: 350; letter-spacing: -0.02em; color: #000;}

/* footer */
footer {margin-top: 30px; padding: 20px 20px 25px; position: relative; align-items: flex-start;}
footer img {margin-right: 15px; max-width: 62px;}
footer .btn_top {position: absolute; margin-right: 0; left: 36px; bottom: 25px;}
footer .copyright {letter-spacing: -0.03em; font-size: 1.4rem; line-height: 1.5;}
footer .copyright p {font-size: 1.2rem;}
footer .copyright span {font-size: 1.0rem; margin-top: 5px; display: block;}

footer.type1 {border-top: none; flex-direction: column; padding: 20px 45px 25px; margin-top: 70px; max-width: 360px;}
footer.type1 img {width: auto; max-width: 100%; margin: 0 auto 12px;}
footer.type1 .btn_top {bottom: auto; top: 20px; left: 45px;}
footer.type1 .copyright {font-size: 1.6rem; letter-spacing: -0.03em; line-height: 1.3; color: #111; margin: 0 auto;}
footer.type1 .copyright span {color: #000; margin-top: 7px;}
footer.type1.top .btn_top {display: none;}

footer.type2 {margin-top: 18px; padding: 30px 20px 30px 35px;}
footer.type2 .copyright span {margin-top: 0;}
footer.type2 .btn_top {position: initial; margin-right: 30px;}

/* login */
.contents.login {margin-top: 25px;}
.default_login .form input, 
.default_login .btn_type1,
.sns_login .btn_type2 {width: 260px;}

/* join */
.contents.join {margin-top: 25px;}
.contents.join input:not(input[type="radio"]) {width: 100%; min-width: 180px}
.contents.join section {padding: 0 0 30px;}
.contents.join section .title {display: none;}
.contents.join .form {max-width: 260px;}
.contents.join .form .id input, .contents.join .form .nickname input {width: 180px;}
.contents.join input[type="text"] + label, .contents.join input[type="number"] + label, .contents.join input[type="date"] + label {margin-left: 0;}
.login_info .form div p {max-width: 260px; margin: 10px auto 0;}
.profile_info .form .photo dt {width: 120px; height: 120px;}
.profile_info .form .photo dd {margin-left: 10px;}
.profile_info .form .photo .btn_type1 {margin-top: 2px;}
.contents.join .name input[type="text"] {min-width: 120px; width: 120px;}
.profile_info .form > div:not(.name) input {max-width: 180px;}
.profile_info .form .address input:last-of-type {max-width: 100%;}
.contents.join section.area_event {padding-right: 0; justify-content: center;}
.contents.join .area_event .form {max-width: 320px;}
.area_event .form .event button:nth-of-type(5n+5) {margin-right: 0;}
.join .btn_type1.complete {margin: 0 auto;}

/* paging */
.paging {margin-top: 18px;}
/******************** //common ********************/

/******************** Main ********************/
/* main_visual */
.visual .img {width: 100%; min-height: 450px; overflow: hidden;}
.visual img {min-width: 100%; max-width: 100%;}
.visual .dots {margin-top: 10px;}
.visual .dots .dot {width: 8px; height: 8px; margin: 5px;}

.visual {position: relative;}
.visual .btn_visual {position: absolute; width: calc(100% - 40px); top: calc(50% - 12px); display: flex; justify-content: space-between; padding: 0 6px;}
.visual .arrow {width: 9px; height: 13px; position: absolute; top: calc(50% - 12px); cursor: pointer;}
.visual .btn_prev {background: url(../images/00_main/mo/btn_visual_arrow.svg) no-repeat left center; left: 26px;}
.visual .btn_next {background: url(../images/00_main/mo/btn_visual_arrow.svg) no-repeat right center; right: 26px; transform: rotate(180deg);}

/* schedule */
.schedule {padding-bottom: 30px;}
.schedule .sec01 {flex-direction: column-reverse; align-items: flex-start;}
.schedule .sec01 > div {width: 100%;}
.schedule .login {flex-direction: row-reverse; justify-content: space-between; font-size: 1.6rem; margin-top: 0;}
.schedule .login .btn_login {width: 60px; height: 60px; min-width: 60px; margin-right: 0; margin-left: 10px;}
.schedule .list {margin-top: 23px; padding-right: 0;}
/* .schedule .list .event {padding-right: 0;} */
/* .schedule .list .event a {margin-right: 2px;} */
.schedule .list .btn_plus {bottom: 0;}

.schedule.type1 {border-bottom: none; padding: 22px 20px 0;}

/* slide list */
.slide_list a.btn_r_black {color: #fff; min-width: 65px; text-align: center;}
.slide_list .title_box {align-items: flex-end;}
.slide_box {overflow: hidden;}
.slide_box .silde01 > div {min-width: 230px; width: 100%; margin-right: 0;}
.slide_box .silde01 img {min-height: 230px;min-width: 230px; max-width: 230px; max-height: 230px;  object-fit: cover;}

/* notice */
.notice {flex-direction: column;}
.notice > div {width: 100%;}
.notice .list {padding-right: 0;}
.notice .list ul li p {width: 78%;}
.notice .list .title {display: flex; justify-content: space-between; align-items: center; font-size: 2.4rem;}
.notice .list .title a {color: #fff;}
.woog_me {padding-left: 0;}
.woog_open {flex-direction: column; align-items: flex-start; margin-top: 78px;}
.woog_open a {min-width: 100%; padding-left: 77px;} 
.woog_open p {margin: 20px 0 0 15px; font-size: 1.2rem;}

/* login 이후 */
.my_schedule {margin-top: 32px;}
.my_info {flex-direction: column; padding-bottom: 25px; border-bottom: none;}
.my_info > div {width: 100%; max-width: 100%; margin-right: 0;}
.my_info .data {border-bottom: 1px dashed #111; padding-bottom: 20px; width: 100%; max-width: 100%;}
.my_info .choice .tx_tit02 {margin-bottom: 7px;}
.my_info .choice {padding-top: 20px; width: 100%;}
.my_info .choice .tab {flex-direction: column; align-items: flex-start; justify-content: flex-start;}
.my_info .choice .tab_title a {font-size: 2.1rem;}
.my_info .choice .tab_title::after {display: none;}
.my_info .contest {align-self: flex-start; padding: 5px 0;}

.my_contest {flex-direction: column; border-bottom: none; padding: 0;}
.my_contest > div {width: 100%; max-width: 100%; margin-right: 0; padding: 35px 20px 30px;}
.my_contest > div:nth-of-type(1) {background: #f3f3f3; order: 1;}
.my_contest > div:nth-of-type(2) {background: rgba(233, 233, 233, 0.6667); order: 2;}
.my_contest > div:nth-of-type(3) {background: rgba(241, 241, 241, 0.6667); order: 0;}

/* search */
.contents.search .schedule.type1 {margin-top: 50px;}
.contents.search .schedule {border-bottom: 3px solid #111; padding-bottom: 25px;}
.contents.search .schedule .list .event {max-height: 70px;}
.contents.search .slide_list {margin: 30px 0 50px;}

.search_section {flex-direction: column; margin-top: 0;}
.search_section .search_title {width: 100%; max-width: 100%;}
.search_section .search_contents {width: 100%; max-width: 100%;}
.search_section .search_box {margin-top: 75px; text-align: center; padding: 0 20px;}
.search_section .search_box input {width: 160px;}

.search_section.list .search_contents .search_box {margin-top: 15px; border-bottom: 3px solid #111; padding: 15px 20px 35px;}
.search_section.list .tx_result {margin-top: 15px; font-size: 1.6rem;}
.search_section.list .search_list {padding: 0 20px 22px; margin-top: 35px;}

.search_list.type1 ul li b {width: 70%; max-width: 100%;}
.search_list.type1 ul li span.mo {font-size: 1.4rem; width: 30%; text-align: right;}

.search_list.type2 ul li b {width: 70%;}
.search_list.type2 ul li .date {width: 20%;}
.search_list.type2 ul li span:last-of-type {width: 10%;}

/******************** //Main ********************/

/******************** My page ********************/
.my_profile {flex-direction: column; border-bottom: none; padding: 20px 0 0;}
.my_profile > div {width: 100%;}
.my_profile .title {display: none;}
.my_profile .my_id {max-width: 100%; width: 100%; font-size: 1.8rem; font-weight: 100; letter-spacing: -0.05em; line-height: 1; margin-bottom: 30px; text-align: center;}
.my_profile .my_id > span {font-size: 3.0rem; letter-spacing: -0.03em; display: block; margin-bottom: 10px;}
.my_profile .photo {margin: 0 auto;}
.my_profile .my_info {margin-left: 0;}
.my_profile .modify {text-align: center; margin: 10px 0 0;}
.my_profile .my_info .choice {padding-top: 30px;}

.contents.mypage.apply .my_profile, .contents.mypage.wish .my_profile, .contents.mypage.apply .my_profile, .contents.mypage.point .my_profile {display: flex;}
.contents.mypage .list_type1 .search_box {margin-left: 0;}
.contents.mypage .list_type1 {margin-top: 0;}
.contents.mypage .list_type1 .bar {margin-top: 0;}
.contents.mypage .list_type1 .search_box {margin-top: 0;}
.contents.mypage .list_type1 ul li {padding: 15px 20px;}
.contents.mypage .list_type1 ul li:first-of-type {padding: 25px 20px 15px;}
.contents.mypage .list_type1 ul li:last-of-type {padding: 15px 20px 25px;}
.contents.mypage .list_type1 .list_date a {font-size: 1.2rem;}

.mypage.point .list_type1 .bar .date_search {justify-content: center;}
.mypage .date_search input[type="date"] {width: 90px; padding: 3px;}
/******************** //My page ********************/

/******************** List type ********************/
.list_type1 .bar .tx_type03 {letter-spacing: -0.05em;}
.list_type1 {margin-top: 30px; padding: 0;}
.list_type1 .bar {flex-direction: column; justify-content: flex-start; align-items: center; margin-top: 20px; flex-flow: wrap; padding: 0 20px 20px;}
.odr1 {order: 1; width: 100%;}
.odr2 {order: 2; width: 100%;}
.odr3 {order: 3; width: 50%;}
.odr4 {order: 4; width: 50%;}
.list_type1 .search_box {margin: 10px 0;}
.list_type1 select {border-radius: 0; min-width: 70px; width: 70px;}
.list_type1 .search input {border-radius: 0;}
.list_type1 .search label {right: -53px; top: 0px; background: #777; color: #fff; padding: 6px 13px; border-radius: 5px; line-height: 1.2;} 
.list_type1 .tx_type03.odr3 {font-size: 1.4rem;}
.list_type1 .list_date {text-align: right;}
.list_type1 ul.list {padding: 30px 20px;}
.list_type1 ul.list b {display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.list_type1 ul.list li {margin-bottom: 24px;}
.list_type1 ul.list li span {display: block; padding-left: 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
/******************** //List type ********************/

/******************** Contest ********************/
.contest {margin-bottom: 18px;}
.contents .top_line {display: initial;}
.contents .sub_title {text-align: center; background-position: 20px center; font-size: 1.6rem; font-weight: 500; letter-spacing: 0; padding-left: 0; margin-top: 0;}
.contents .sub_title3 {text-align: center; font-size: 1.6rem; font-weight: 500; letter-spacing: 0; margin-top: 0; background: url(../images/common/ico_arrow_g.svg) no-repeat 20px center;}

.contest .summary, .contest .category, .contest .s_notice, .contest .prize, .contest .application, .ground .ground_section {flex-direction: column; padding-top: 10px; border-bottom: 1px dashed #111;}
.contest .summary > h2, .contest .category > h2, .contest .s_notice > h2, .contest .prize > h2, .contest .application > h2, .ground .ground_section > h2 {font-size: 1.6rem; font-weight: 500; letter-spacing: 0.03em; line-height: 1.5; padding: 13px 20px; min-width: 360px; max-width: 100%; margin: 0 auto;}
.contest .sub_menu {padding: 0 20px; width: 100%; max-width: 360px; margin: 0 auto;}
.contest .sub_menu .tab {justify-content: space-between;}
.contest .poster {margin: 12px auto 0;}
.contest .poster img{width: 100%; height: 100%;object-fit: fill;}

.summary_content {display: flex; flex-direction: column;}
.summary_content .title {display: none;}
.summary_content .info {padding: 0 20px 45px; max-height: 740px; overflow: hidden; transition: max-height .5s ease-in-out; -webkit-transition: max-height .5s ease-in-out;}
.summary_content .info.accordion.open {height: auto; max-height: 8000px; transition: max-height .7s ease-in-out; -webkit-transition: max-height .7s ease-in-out;}

.summary_content .file {margin: 25px 0; padding: 0 20px ;}
.summary_content .btn_line {padding: 0 20px 40px; margin-top: 0; text-align: center;}
.summary_content .tx_type04 {padding: 10px 20px 0;}
.summary + .slide_list {margin-bottom: 45px;}
.summary + .slide_list .title_box {justify-content: space-between; height: auto;}

.contest .category .sub_menu .poster, .contest .s_notice .sub_menu .poster, .contest .prize .sub_menu .poster, .contest .application .sub_menu .poster {display: none;}
.contest .category .sub_menu .btn_line, .contest .s_notice .sub_menu .btn_line, .contest .prize .sub_menu .btn_line, .contest .application .sub_menu .btn_line {display: none;}
.category_content .title, .notice_content .title, .prize_content .title, .application_content .title {display: none;}
.category_content .info {margin-top: 12px;}
.category_content .info .search_box, .application_content .info .search_box {padding: 0 20px; text-align: center;}
.category_content .info .search_box select + select {margin-left: 0; min-width: 105px;}
.category_content .table_type1 thead th:first-of-type, .category_content .table_type1 tbody td:first-of-type, .application_content .table_type1 thead th:first-of-type, .application_content .table_type1 tbody td:first-of-type {padding-left: 5px;}
.category_content .table_type1 thead th:last-of-type, .category_content .table_type1 tbody td:last-of-type, .application_content .table_type1 thead th:last-of-type, .application_content .table_type1 tbody td:last-of-type  {padding-right: 5px;}
.category_content .btn_line, .notice_content .btn_line, .prize_content .btn_line, .application_content .btn_line {padding: 0 20px; text-align: center; margin-bottom: 24px;}
.category_content .btn_line .tx_type04, .notice_content .btn_line .tx_type04, .prize_content .btn_line .tx_type04, .application_content .btn_line .tx_type04  {margin-top: 7px;}

.notice_content .info {margin-top: 12px;}
.notice_content .info .search_box {padding: 0 20px; max-width: 360px; margin: 0 auto;}
.notice_content .search_box input {width: 148px;}
.notice_content .search_box a {margin-left: 3px;}
.notice_content .qna .question, .notice_content .qna .answer {padding: 25px 20px;}
.notice_content .table_type1 thead th:first-of-type, .notice_content .table_type1 tbody td:first-of-type {padding-left: 20px;}
.notice_content .table_type1 thead th:last-of-type, .notice_content .table_type1 tbody td:last-of-type {padding-right: 20px;}

.notice_content .table_type1 tbody td.tit {font-size: 1.8rem;}
.notice_content .table_type1 .accordion {padding: 0 20px; max-height: 450px; overflow: hidden; transition: max-height .5s ease-in-out; -webkit-transition: max-height .5s ease-in-out;}
.notice_content .table_type1 .accordion.open {max-height: 10000px; transition: max-height .7s ease-in-out; -webkit-transition: max-height .7s ease-in-out;}

.notice_content .file {padding: 0 20px;}
.notice_content .comment_input {padding: 0 20px;}
.notice_content .comment_input input {margin-bottom: 5px; width: 100%; max-width: 100%;}
.notice_content .comment_input .input_bt {order: 2; flex-grow: 2;}
.notice_content .comment_input span {order: 3; flex-grow: 0;}
.notice_content .comment_input button {order: 4; flex-grow: 0; margin-left: 5px; background: #777;}
.notice_content .comment_view {margin-bottom: 0;}
.notice_content .comment_view .accordion {padding: 0 20px; max-height: 600px;}
.notice_content .paging_arrow {padding-top: 20px;}

.prize_content {border-top: 1px dashed #111; margin-top: 20px;}
.prize_content .info {margin-top: 20px; padding: 0 20px 50px;}
.prize_content .info .tit {font-size: 1.8rem;}
.prize_content .info .tx_info {font-size: 1.4rem;}
.prize_content .btn_line {margin-bottom: 50px;}

.ad_sports_content .tit {font-size: 2.4rem; display: flex; align-items: center; justify-content: space-between; padding: 0 20px;}
.ad_sports_content .list {padding: 0 20px; margin-top: 15px;}
.ad_sports_content .list li {width: 100%;}
.ad_sports_content .list li > p {font-size: 1.6rem;}
.ad_sports_content .list li > span {font-size: 1.4rem;}

.contest .application {border-bottom: none;}
.contest .application .sub_menu .tit {font-size: 2.2rem; margin: 22px 0 0;}
.application_content .info {margin-top: 0;}
.application_content .info .search_box {flex-direction: column;}
.application_content .info .search_box .tit {margin: 15px 0; width: 100%; max-width: 320px; text-align: left;}
.application_content .step1 .table_type1 th:first-of-type, .application_content .step1 .table_type1 td:first-of-type {display: none;}
.application_content .check_area .table_type2 table {width: 100%; margin-bottom: 17px;}
.application_content .check_area .table_type2 table:last-of-type {margin-bottom: 0;}
.application_content .check_area .table_type2 table:nth-of-type(odd) {margin-right: 0;}

.payment .application_content .list {border-bottom: 3px solid #111;}
.payment .application_content .list ul:last-of-type {border-bottom: none;}
.payment .application_content .list ul .tit {width: 20%;}
.payment .application_content .list ul .tit + span {width: calc(80% - 6px);}
.payment .application_content .list ul li:not(.details) {padding: 5px 20px;}
.payment .application_content .list ul .details > .tit {display: none;}
.payment .application_content .list ul .detail_box > .tit {display: inherit; width: 20%; color: #555;}  
.payment .application_content .list .detail_box {justify-content: flex-end; align-items: flex-start;}
.payment .application_content .list .detail_box > div {width: 80%; margin: 0 0 15px 0;}
.payment .application_content .list .detail_box > div:nth-of-type(even) {margin-left: 0;}
/* .payment .application_content .list .detail_box > div .first_line {justify-content: flex-start;} */
.payment .application_content .list .detail_box > div .first_line {justify-content: flex-start;display: block;}
.payment .application_content .list .detail_box > div .first_line span {font-weight: 700; width: auto;}
.payment .application_content .list .detail_box > div .first_line span:nth-of-type(3) {width: 23%;}
.payment .application_content .list .detail_box > div .first_line span:not(.pay1):not(:nth-of-type(3))::after {content:"|"; display: inline-block; clear: both; padding: 0 2px;}

.payment .application_content .list .deduct .tit {width: 30%;}
.payment .application_content .list .total .tit {width: 30%; vertical-align: middle;}
.payment .application_content .list .total .tit + span {width: calc(70% - 6px); vertical-align: middle;}
.payment .application_content .tx_type07 {padding: 0 20px; letter-spacing: -0.01em; margin-top: 25px;}

/******************** //Contest ********************/

/******************** Ground ********************/
.ground .list_type1 .search_box {flex-grow: 0;}
.ground .list_type1 .bar .tx_type03 {flex-grow: 0; width: 50%}

.ground .table_type1 {padding: 0 20px;}
.ground .table_type1 tbody td:last-of-type {font-size: 1.4rem;}

.ground .ground_section {border-bottom: none; margin-bottom: 0;}
.ground .ground_section > h2 span {font-size: 1.2rem; margin-right: 10px;}
.ground_section .slide {margin: 0 auto;}
.ground_content {padding: 0 20px;}
.ground_content ul {max-width: 320px; margin: 40px auto 0;}
.ground_content ul li {padding: 8px 0;}
.ground_content ul li p {font-size: 1.2rem; width: 20%;}
.ground_content ul li span {font-size: 1.4rem; width: 80%;}
.ground_section .map {max-height: 450px; max-width: 320px; margin: 65px auto 0;}
.ground_section .map img {max-height: 450px; width: auto;}
/******************** //Ground ********************/

/******************** Event ********************/
.event .list_type1 .bar {border-bottom: 1px dashed #111;}
.event .list_type1 .bar .tx_type03 {display: none;}

.event_list .table_type1 thead, .event .event_list .table_type1 tbody td:nth-of-type(2n+2) {display: none;}
.event_list .table_type1 table {padding: 0 20px;}
.event_list .table_type1 tbody .date {font-size: 1.2rem;}

.event .event_contents > h2 {font-size: 1.6rem; font-weight: 500; letter-spacing: 0.03em; line-height: 1.5; padding: 13px 20px; min-width: 360px; max-width: 100%; margin: 0 auto;}

.event .event_contents {padding-top: 10px;}
.event .notice_content .title {display: inherit; padding: 13px 20px 4px;}
.event .title h2 {font-size: 1.6rem;}
.event .event_contents .img_info {padding: 0 20px 30px;}
.event .event_contents .img_info .text_type3 {font-size: 1.4rem;}
.event .event_contents .paging_arrow {margin-bottom: 65px;}
.event .event_contents .img_info .tx_type04 {margin-top: 0; text-align: right; font-size: 1.2rem; color: #333; margin-top: 10px;}
/******************** //Event ********************/

/******************** CS Notice ********************/
.cs_notice .cs_notice_list {flex-direction: column; padding-top: 10px;}
.cs_notice_list .notice_content .info {margin-top: 12px;}

.cs_notice_list .notice_content.order .info {display: flex; flex-direction: column;}
.cs_notice_list .notice_content.order .info .search_box {order: 2; margin-top: 20px;}
.cs_notice_list .notice_content.order .qna {order: 1; border-top: none; margin-top: -20px;}
.cs_notice_list .notice_content.order .table_type1 {order: 3;}
.cs_notice_list .paging {order: 4;}
/******************** //CS Notice ********************/

/******************** Popup ********************/
.popup_layer {min-width: 360px; max-width: 80%; height: 500px; padding: 10px;}
.popup_layer > p {text-align: left; font-size: 1.2rem; margin-top: 12px;}
.popup_layer textarea {max-height: 280px; margin-top: 12px;}
.popup_layer .btn_type1 {margin-top: 20px;}

.popup_layer .search_box {flex-direction: column; justify-content: flex-start;}
.popup_layer .search_box > div {width: 100%; text-align: left;}
.popup_layer .search_box > div + div {margin-top: 10px;}
.popup_layer .search_box .btn_type3 {margin-left: 0;}

.popup_layer .table_type1 {max-height: 210px; overflow: hidden; overflow-y: auto;}
.popup_layer .table_type1 tbody td {font-size: 1.4rem;}
.contest .popup_layer .table_type1 tbody tr:first-of-type td {padding-top: 0;}
/******************** //Popup ********************/

.pc {display: none;}

@media all and (max-width:375px) {
  .mypage .date_search input[type="date"] {font-size: 1.1rem; letter-spacing: -0.05em;}
}

}